<template>
  <div>
    <calc-result-page>
      <div class="center">
        <div class="center-title">预计补缴养老费：</div>
        <div class="money-box">{{ res.amount }}<span>元</span></div>
        <div class="title-box">
          <img src="@/assets/imgs/calc/bias.png" />补缴构成
        </div>

        <div v-for="(item, idx) in res.details" :key="idx">
          <div v-bind:class="['item-box', idx % 2 == 0 ? 'item-bk' : '']">
            <div class="time-box">
              <div>{{item.begDate | yyyyMMFormat }}</div>-<div>{{item.endDate | yyyyMMFormat}}</div>
            </div>
            <div>{{item.money}}元</div>
          </div>
          <van-divider />
        </div>

        <div class="button-box">以上结果只是测算，最终以社保局计算结果为准</div>
      </div>
    </calc-result-page>
  </div>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
import { Divider } from "vant";
export default {
  components: {
    CalcResultPage,
    [Divider.name]: Divider,
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
  data() {
    return {
      res: {},
    };
  },
};
</script>

<style scoped>
.center {
  padding: 0 14px;
}

.money-box {
  font-size: 44px;
  font-weight: bold;
  line-height: 54px;
  color: #658CF1;
}

.money-box span {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #999999;
}

.center-title {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  color: #333;
  text-align: left;
  margin-bottom: 12px;
}

.title-box {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  color: #666666;
  display: flex;
  align-items: center;
  margin: 30px 0 20px 0;
}

.title-box img {
  width: 8px;
  height: 18px;
  margin-right: 2px;
}

.item-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.center >>> .van-divider {
  margin: 0;
}

.item-bk {
  background: #f6f9fc;
}

.time-box {
  display: flex;
}

.button-box {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #658CF1;
  margin: 24px 0 0 16px;
}
</style>